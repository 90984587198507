import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    combine,
    validate,
    validators,
    validatorFromFunction,
} from "validate-redux-form";
import { renderField, renderSelectField } from "../../Utils/renderField";
import { user_types } from "../../../../utility/constants";

const CrearEditarForm = (props) => {
    const { handleSubmit, edit, recursos, type } = props;
    const [isScrum, setIsScrum] = useState(false);
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    useEffect(() => {
        if(type && edit) {
            setIsScrum(type == 10 ? true : false);
        } else {
            setIsScrum(false);
        }
    }, [type, edit]);

    const changeSelect = (value) => {
        if(value == 10) setIsScrum(true)
        else setIsScrum(false)
    }
    
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="first_name">Nombres</label>
                    <Field
                        name="first_name"
                        label="Nombres"
                        component={renderField}
                        className="input--style"
                        type="text"
                    />
                </div>
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="last_name">Apellidos</label>
                    <Field
                        name="last_name"
                        label="Apellidos"
                        component={renderField}
                        type="text"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="username">Nombre de usuario</label>
                    <Field
                        name="username"
                        label="Nombre de usuario"
                        component={renderField}
                        type="text"
                        className="input--style"
                    />
                </div>
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="Email"
                        component={renderField}
                        type="text"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="type">Tipo de usuario</label>
                    <Field
                        name="type"
                        label="Tipo de usuario"
                        options={user_types}
                        component={renderSelectField}
                        extra_change={true}
                        extraChange={changeSelect}
                        labelKey="label"
                        valueKey="value"
                        select_style={select_style}
                    />
                </div>
                {isScrum ? <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="puesto">Recurso</label>
                    <Field
                        name="recurso"
                        label="Recurso"
                        options={recursos.filter((recurso) =>
                            recurso.puesto.nombre
                                .toUpperCase()
                                .includes("SCRUM")
                        )}
                        component={renderSelectField}
                        labelKey="nombre"
                        valueKey="id"
                        select_style={select_style}
                    />
                </div> : null}
                {!edit ? (
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="password">Contraseña</label>
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type="password"
                            className="input--style"
                        />
                    </div>
                ) : null}
                {!edit ? (
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="confirm_password">
                            Confirmar contraseña
                        </label>
                        <Field
                            name="confirm_password"
                            label="Confirmar contraseña"
                            component={renderField}
                            type="password"
                            className="input--style"
                        />
                    </div>
                ) : null}
            </div>
            <div className="buttons-box">
                <Link
                    to="/users"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export const matchPassword = (passe, confirm) =>
    validatorFromFunction(() => {
        return passe === confirm;
    });

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            first_name: validators.exists()("Este campo es requerido"),
            last_name: validators.exists()("Este campo es requerido"),
            username: validators.exists()("Este campo es requerido"),
            type: validators.exists()("Este campo es requerido"),
            recurso: validators.exists()("Este campo es requerido"),
            password: validators.exists()("Este campo es requerido"),
            confirm_password: combine(
                validators.exists()("Este campo es requerido"),
                matchPassword(data.password, data.confirm_password)()(
                    "Las Contraseña no coinciden"
                )
            ),
        });
    },
})(CrearEditarForm);
