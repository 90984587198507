import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSelectField } from "../../Utils/renderField";
import Eliminar from "./Eliminar";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const CrearEditarForm = (props) => {
    const {
        handleSubmit,
        pristine,
        reset,
        submitting,
        puestos,
        tipos_proyecto,
        tipos_contratacion,
        edit,
        renunciaDespido,
        id
    } = props;

    const [popup_opened, setPopupOpened] = useState(false);
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <Popup
                    open={popup_opened}
                    modal
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                >
                    <Eliminar id={id} renunciaDespido={renunciaDespido} close={setPopupOpened}/>
                </Popup>
            <form
                name="CrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row">
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="codigo">Código</label>
                        <Field
                            name="codigo"
                            label="codigo"
                            component={renderField}
                            type="text"
                            className="input--style"
                        />
                    </div>
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                            name="nombre"
                            label="Nombre"
                            component={renderField}
                            type="text"
                            className="input--style"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="email">Correo electrónico</label>
                        <Field
                            name="email"
                            label="Correo electrónico"
                            component={renderField}
                            type="text"
                            className="input--style"
                        />
                    </div>
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="tipo_contratacion">
                            Fecha inicio labores
                        </label>
                        <Field
                            name="fecha_inicio"
                            label="Fecha inicio labores"
                            component={renderField}
                            className="input--style"
                            type="date"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="puesto">Puesto</label>
                        <Field
                            name="puesto"
                            label="Puesto"
                            options={puestos}
                            component={renderSelectField}
                            select_style={select_style}
                            labelKey="nombre"
                            valueKey="id"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="tipo_contratacion">
                            Tipo de contratación
                        </label>
                        <Field
                            name="tipo_contratacion"
                            label="Tipo de contratación"
                            options={tipos_contratacion}
                            component={renderSelectField}
                            select_style={select_style}
                            labelKey="nombre"
                            valueKey="id"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-12 col-md-6">
                        <label htmlFor="tipo_contratacion">
                            Tipo de proyecto
                        </label>
                        <Field
                            name="proyecto_default"
                            label="Tipo de proyecto"
                            options={tipos_proyecto}
                            component={renderSelectField}
                            select_style={select_style}
                            labelKey="nombre"
                            valueKey="id"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="buttons-box">
                    {edit ? <button
                        className="btn btn-warning m-1"
                        style={{ fontSize: 15 }}
                        type="button"
                        onClick={() => setPopupOpened(true)}
                    >
                        Despido/Renuncia
                    </button> : null}
                    <Link
                        to="/recursos"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </Link>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
};

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            codigo: validators.exists()("Este campo es requerido"),
            nombre: validators.exists()("Este campo es requerido"),
            email: validators.exists()("Este campo es requerido"),
            tipo_contratacion: validators.exists()("Este campo es requerido"),
            puesto: validators.exists()("Este campo es requerido"),
            proyecto_default: validators.exists()("Este campo es requerido"),
            fecha_inicio: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm);
