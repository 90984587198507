import React from "react";
import moment from "moment";
import Swal from "sweetalert2";

const TablaRecursos = (props) => {
    const quetionDelete = (recurso_index) => {
        Swal.fire({
            title: "¿Eliminar?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, eliminar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                props.updateArray(null, recurso_index);
            }
        });
    };

    return (
        <div className="table-responsive">
            <table className="table resources--table">
                <thead className="thead--blue">
                    <tr>
                        <th scope="col">Fase</th>
                        <th scope="col">Recurso</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Porcentaje</th>
                        <th scope="col">Fecha inicio</th>
                        <th scope="col">Fecha final</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {props.recursos_submit
                        ? props.recursos_submit.map((e, index) => {
                              const primer_recurso =
                                  e.recursos.length > 0 ? e.recursos[0] : null;
                              const recursos = [];
                              if (primer_recurso) {
                                  for (let i = 1; i < e.recursos.length; i++) {
                                      recursos.push(e.recursos[i]);
                                  }
                              }
                              return (
                                  <React.Fragment key={index}>
                                      <tr
                                          className={`border--lr border--bt ${
                                              recursos.length == 0
                                                  ? "border--last"
                                                  : null
                                          }`}
                                      >
                                          <th
                                              scope="row"
                                              rowSpan={
                                                  primer_recurso
                                                      ? recursos.length + 1
                                                      : 1
                                              }
                                          >
                                              <p
                                                  style={{
                                                      marginBottom: "0px",
                                                  }}
                                              >
                                                  {e.nombre}
                                              </p>
                                              {e.fecha_inicio ? (
                                                  <p
                                                      style={{
                                                          marginBottom: "0px",
                                                      }}
                                                  >
                                                      {moment(
                                                          e.fecha_inicio
                                                      ).format(
                                                          "DD/MM/YYYY"
                                                      )}{" "}
                                                      -{" "}
                                                      {moment(
                                                          e.fecha_fin
                                                      ).format("DD/MM/YYYY")}
                                                  </p>
                                              ) : null}
                                          </th>
                                          {primer_recurso ? (
                                              <React.Fragment>
                                                  <td>
                                                      {
                                                          primer_recurso.recurso_nombre
                                                      }
                                                  </td>
                                                  <td>
                                                      {
                                                          primer_recurso.puesto_label
                                                      }
                                                  </td>
                                                  <td>
                                                      {
                                                          primer_recurso.porcentaje_asignacion
                                                      }
                                                      %
                                                  </td>
                                                  <td>
                                                  {moment(primer_recurso.fecha_inicio).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                  {moment(primer_recurso.fecha_fin).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                      <a
                                                          className="text-warning"
                                                          onClick={() => {
                                                              props.setEditRecurso(
                                                                  primer_recurso
                                                              );
                                                              props.setEditIndex(
                                                                  {
                                                                      index: 0,
                                                                      fase_proyecto:
                                                                          index,
                                                                  }
                                                              );
                                                              props.setFase(
                                                                  props._fases.find(
                                                                      (g) =>
                                                                          g.value ==
                                                                          primer_recurso.fase_proyecto
                                                                  )
                                                              );
                                                              props.setPersona(
                                                                  props._recursos.find(
                                                                      (h) =>
                                                                          h.value ==
                                                                          primer_recurso.recurso
                                                                  )
                                                              );
                                                              props.setPorcentaje(
                                                                  primer_recurso.porcentaje_asignacion
                                                              );
                                                              document.getElementById(
                                                                  "fecha_inicio"
                                                              ).value =
                                                                  primer_recurso.fecha_inicio;
                                                              document.getElementById(
                                                                  "fecha_fin"
                                                              ).value =
                                                                  primer_recurso.fecha_fin;
                                                              props.setTipo(
                                                                  props._tipo.find(
                                                                      (e) =>
                                                                          e.value ==
                                                                          primer_recurso.puesto
                                                                  )
                                                              );
                                                          }}
                                                      >
                                                          <i className="material-icons">
                                                              edit
                                                          </i>
                                                      </a>
                                                      <a
                                                          className="px-2"
                                                          style={{
                                                              cursor: "pointer",
                                                              color: "#c4183c",
                                                          }}
                                                          onClick={() =>
                                                              quetionDelete({
                                                                  index: 0,
                                                                  fase_proyecto:
                                                                      index,
                                                              })
                                                          }
                                                      >
                                                          <i className="material-icons">
                                                              delete
                                                          </i>
                                                      </a>
                                                  </td>
                                              </React.Fragment>
                                          ) : (
                                              <React.Fragment>
                                                  <td colSpan={6}>
                                                      No hay recursos.
                                                  </td>
                                              </React.Fragment>
                                          )}
                                      </tr>
                                      {recursos.map((f, index1) => (
                                          <tr
                                              key={index1}
                                              className={`border--lr border--bt ${
                                                  index1 == recursos.length - 1
                                                      ? "border--last"
                                                      : null
                                              }`}
                                          >
                                              <td>{f.recurso_nombre}</td>
                                              <td>{f.puesto_label}</td>
                                              <td>
                                                  {f.porcentaje_asignacion}%
                                              </td>
                                              <td>{moment(f.fecha_inicio).format("DD/MM/YYYY")}</td>
                                              <td>{moment(f.fecha_fin).format("DD/MM/YYYY")}</td>
                                              <td>
                                                  <a
                                                      className="text-warning"
                                                      onClick={() => {
                                                          props.setEditRecurso(
                                                              f
                                                          );
                                                          props.setEditIndex({
                                                              index: index1 + 1,
                                                              fase_proyecto:
                                                                  index,
                                                          });
                                                          props.setFase(
                                                              props._fases.find(
                                                                  (e) =>
                                                                      e.value ==
                                                                      f.fase_proyecto
                                                              )
                                                          );
                                                          props.setPersona(
                                                              props._recursos.find(
                                                                  (e) =>
                                                                      e.value ==
                                                                      f.recurso
                                                              )
                                                          );
                                                          props.setPorcentaje(
                                                              f.porcentaje_asignacion
                                                          );
                                                          document.getElementById(
                                                              "fecha_inicio"
                                                          ).value =
                                                              f.fecha_inicio;
                                                          document.getElementById(
                                                              "fecha_fin"
                                                          ).value = f.fecha_fin;
                                                          props.setTipo(
                                                              props._tipo.find(
                                                                  (e) =>
                                                                      e.value ==
                                                                      f.puesto
                                                              )
                                                          );
                                                      }}
                                                  >
                                                      <i className="material-icons">
                                                          edit
                                                      </i>
                                                  </a>
                                                  <a
                                                      className="px-2"
                                                      style={{
                                                          cursor: "pointer",
                                                          color: "#c4183c",
                                                      }}
                                                      onClick={() =>
                                                          quetionDelete({
                                                              index: index1 + 1,
                                                              fase_proyecto:
                                                                  index,
                                                          })
                                                      }
                                                  >
                                                      <i className="material-icons">
                                                          delete
                                                      </i>
                                                  </a>
                                              </td>
                                          </tr>
                                      ))}
                                  </React.Fragment>
                              );
                          })
                        : null}
                </tbody>
            </table>
        </div>
    );
};

export default TablaRecursos;