import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { api } from "api";
import "../../../../../style/custom.css";
import Swal from "sweetalert2";

class CrearEditar extends Component {
    state = {
        recursos: [],
        clientes: [],
        puestos: [],
        fases: [],
        tipos_proyecto: [],
        edit: false,
        loader: true,
        tags: [],
        id: null,
        recursos_submit: [],
        edit_type: null,
        planificacion: []
    };

    componentDidMount() {
        this.props.getRole();
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        api.get("recurso/all", { activo: true, despedido: false, renuncia: false }).then((response1) => {
            this.setState({ recursos: response1 });
            api.get("tipo_proyecto/filter_list").then((response2) => {
                this.setState({ tipos_proyecto: response2 });
                api.get("puesto/filter_list").then((response3) => {
                    this.setState({ puestos: response3 });
                    api.get("cliente/filter_list").then((response5) => {
                        this.setState({ clientes: response5 });
                        api.get("fase_proyecto/filter_list").then((response4) => {
                            this.setState({
                                fases: response4,
                                loader: false,
                            });
                            if (edit == true) {
                                this.setState({
                                    edit_type: this.props.match.params.tipo,
                                });
                                const id = this.props.match.params.id;
                                this.setState({ id });
                                this.props.obtenerProyecto(id);
                            }
                        });
                    });
                });
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.formData != this.props.formData) {
            const tags = this.props.formData.tags;
            this.setState({ tags: tags ? tags : [] });
        }
    }

    onSubmit = (data) => {
        const { edit , id} = this.state;
        const { onSubmit, actualizarProyecto } = this.props;
        let recursos = [];
        this.state.recursos_submit.forEach((e) => {
            recursos = recursos.concat(e.recursos);
        });
        data = {
            ...data,
            recursos: recursos,
            tags: this.state.tags,
            fases: this.state.planificacion,
        };
        console.log(data);
        if (edit) {
            actualizarProyecto(data, id);
        } else {
            onSubmit(data);
        }
    };

    setTags = (tags) => {
        this.setState({ tags });
    };

    setRecurosSubmit = (recursos_submit) => {
        this.setState({ recursos_submit });
    };

    setPlanificacion = (planificacion) => {
        this.setState({ planificacion });
    };

    finalizarProyecto = (data = null) => {
        Swal.fire({
            title: "¿Finalizar?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, finalizar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                if(data) {
                    let recursos = [];
                    this.state.recursos_submit.forEach((e) => {
                        recursos = recursos.concat(e.recursos);
                    });
                    let data1 = {
                        ...data,
                        recursos: recursos,
                        tags: this.state.tags,
                        fases: this.state.planificacion,
                    };
                    this.props.finalizarProyecto1(this.state.id, data1);
                } else {
                    this.props.finalizarProyecto(this.state.id);
                }
            }
        });
    };

    render() {
        const { loader } = this.props;
        const finalizado = this.props.formData.finalizado;
        
        return (
            <React.Fragment>
                <LoadMask loading={loader || this.state.loader} light>
                    <div className="d-flex flex-column align-items-start pt-3">
                        <h3 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} PROYECTO
                        </h3>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                recursos={this.state.recursos}
                                fases={this.state.fases}
                                tipos_proyecto={this.state.tipos_proyecto}
                                change={this.props.change}
                                tags={this.state.tags}
                                setTags={this.setTags}
                                recursos_submit={this.state.recursos_submit}
                                setRecurosSubmit={this.setRecurosSubmit}
                                recursos_update={this.props.formData.recursos}
                                planificacion_update={this.props.formData.fases}
                                puestos={this.state.puestos}
                                clientes={this.state.clientes}
                                edit={this.state.edit}
                                role={this.props.user.role}
                                finalizarProyecto={this.finalizarProyecto}
                                finalizado={finalizado}
                                edit_type={this.state.edit_type}
                                planificacion={this.state.planificacion}
                                setPlanificacion={this.setPlanificacion}
                                tipo_proyecto={this.props.formData.tipo_proyecto}
                                finalizar_data={this.props.formData}
                            />
                        </div>
                    </div>
                </LoadMask>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
