import LoadMask from "Utils/LoadMask/LoadMask";
import ChangePasswordForm from "./ChangePasswordForm";
import React, { Component } from "react";

class ChangePassword extends Component {
    state = {
        loader: true,
    };

    componentDidMount() {
        this.setState({ loader: false });
    }

    onSubmit = (data) => {
        const { changePassword } = this.props;
        console.log(data);
        changePassword(data);
    };

    render() {
        const { loader } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            CAMBIAR CONTRASEÑA
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader || this.state.loader} light>
                            <ChangePasswordForm
                                onSubmit={this.onSubmit}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ChangePassword;
