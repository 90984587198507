import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Eliminar from "../../Recursos/CrearEditar/Eliminar";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popup_opened: false
        }
    }

    eliminar = (id, params={}) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if(params) this.props.eliminar(id, params); 
                    else this.props.eliminar(id);
                }
            });
        }
    };

    finalizar = () => {
        this.setState({ popup_opened: true });
    }

    setPopupOpened = () => {
        this.setState({ popup_opened: false });
    }

    render() {
        const { id, ver, editar, eliminar, general, recursos, finalizar, params } = this.props;

        return (
            <React.Fragment>
                <Popup
                    open={this.state.popup_opened}
                    modal
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                >
                    <Eliminar id={id} renunciaDespido={finalizar} params={params} close={this.setPopupOpened}/>
                </Popup>
                <div className="d-flex justify-content-center">
                    {(ver !== undefined) && (
                        <Link to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                    )}
                    {(editar !== undefined) && (
                        <Link className="text-warning" to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                    )}
                    {(general !== undefined) && (
                        <Link className="text-secundary" to={`proyectos/${id}/editar/general`} ><i className="material-icons">receipt</i></Link>
                    )}
                    {(recursos !== undefined) && (
                        <Link className="text-info" to={`proyectos/${id}/editar/recursos`} ><i className="material-icons">supervisor_account</i></Link>
                    )}
                    {(eliminar !== undefined) && (
                        <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id, params)}><i className="material-icons">delete</i></a>
                    )}
                    {(finalizar !== undefined) && (
                        <a className="px-2 text-warning" style={{cursor: "pointer"}} onClick={() => this.finalizar()}><i className="material-icons">receipt</i></a>
                    )}
                    {(finalizar == undefined && recursos == undefined && general == undefined && eliminar == undefined && editar == undefined && ver == undefined) && (
                        <span>Acciones no disponibles</span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones}/> )
    };
}
