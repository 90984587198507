import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import recursos from './modules/recursos/recursos'
import proyectos from './modules/proyectos/proyectos'
import gantt from './modules/gantt/gantt';
import puestos from './modules/puestos/puestos'
import fases_proyecto from './modules/fases_proyecto/fases_proyecto';
import users from './modules/users/users';
import statistics from './modules/statistics/statistics';
import clientes from './modules/clientes/clientes';
import tipos_proyecto from './modules/tipos_proyecto/tipos_proyecto';

export default combineReducers({
    form: formReducer,
    login,
    gantt,
    routing,
    recursos,
    proyectos,
    puestos,
    fases_proyecto,
    users,
    statistics,
    clientes,
    tipos_proyecto
});
