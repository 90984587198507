import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sublist: [
                {
                    id: 'proyecto_sublist',
                    show: false
                },
                {
                    id: 'admin_sublist',
                    show: false
                },
                {
                    id: 'recurso_sublist',
                    show: false
                },
                {
                    id: 'usuario_sublist',
                    show: false
                }
            ]
        }
    }

    showSublist(index) {
        const copy = this.state.sublist;
        const sub = copy[index]
        let subdoc = document.getElementById(sub.id);
        copy.forEach(e => {
            let subdoc1 = document.getElementById(e.id);
            if(subdoc1) {
                subdoc1.classList.remove('sublist--show');
                sub.show = false;
            }
        });
        subdoc.classList.add('sublist--show');
        sub.show = true;
        copy[index] = sub;
        this.setState({ sublist: copy });
    }

    render() {
        const { toggleOpen, navToggle, logOut } = this.props;
        const role = localStorage.getItem("role");
        return (
            <aside
                className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${
                    toggleOpen ? "" : "open"
                }`}
            >
                <div className="main-navbar">
                    <nav className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light">
                        <a href="#" className="w-100 mr-0 navbar-brand">
                            <div className="d-table m-auto">
                                <img
                                    id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require("assets/img/logo.png")}
                                    alt="Logo"
                                />
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}
                        >
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item">
                            <NavLink
                                to="/"
                                exact
                                className="nav-link "
                                activeClassName={"active"}
                                onClick={navToggle}
                            >
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons title--i">home</i>
                                </div>
                                <span>Inicio</span>
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={() => this.showSublist(0)}>
                            <div className="nav-link ">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons title--i">book</i>
                                </div>
                                <span>Proyecto</span>
                                <div className="d-inline-block item-icon-wrapper ml-3">
                                    <i className="material-icons rotate" style={{color: 'black'}}>arrow_drop_down</i>
                                </div>
                                <div className="sublist" id="proyecto_sublist">
                                <ul className="nav--no-borders flex-column nav">
                                    {role == 1 || role == 10 ? (
                                        <li className="nav-item">
                                            <NavLink
                                                to="/proyectos"
                                                exact
                                                className="nav-link i--h"
                                                activeClassName={"active"}
                                                onClick={navToggle}
                                            >
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i className="material-icons">book</i>
                                                </div>
                                                <span>Listado</span>
                                            </NavLink>
                                        </li>
                                    ) : null}
                                    {role == 1 ? (
                                        <li className="nav-item">
                                            <NavLink
                                                to="/clientes"
                                                exact
                                                className="nav-link i--h"
                                                activeClassName={"active"}
                                                onClick={navToggle}
                                            >
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i className="material-icons">
                                                        supervisor_account
                                                    </i>
                                                </div>
                                                <span>Clientes</span>
                                            </NavLink>
                                        </li>
                                    ) : null}
                                    <li className="nav-item">
                                        <NavLink
                                            to="/gantt"
                                            exact
                                            className="nav-link "
                                            activeClassName={"active"}
                                            onClick={navToggle}
                                        >
                                            <div className="d-inline-block item-icon-wrapper">
                                                <i className="material-icons">
                                                    waterfall_chart
                                                </i>
                                            </div>
                                            <span>Gantt</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/resumen"
                                            exact
                                            className="nav-link "
                                            activeClassName={"active"}
                                            onClick={navToggle}
                                        >
                                            <div className="d-inline-block item-icon-wrapper">
                                                <i className="material-icons">
                                                    table_chart
                                                </i>
                                            </div>
                                            <span>Resumen</span>
                                        </NavLink>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </li>
                        {role == 1 ? (<li className="nav-item" onClick={() => this.showSublist(1)}>
                            <div className="nav-link ">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons title--i">settings</i>
                                </div>
                                <span>Administración</span>
                                <div className="d-inline-block item-icon-wrapper ml-3">
                                    <i className="material-icons rotate" style={{color: 'black'}}>arrow_drop_down</i>
                                </div>
                                <div className="sublist" id="admin_sublist">
                                    <ul className="nav--no-borders flex-column nav">
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/fases-proyecto"
                                                    exact
                                                    className="nav-link i--h"
                                                    activeClassName={"active"}
                                                    onClick={navToggle}
                                                >
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <i className="material-icons">
                                                            assignment
                                                        </i>
                                                    </div>
                                                    <span>Fases Proyecto</span>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/tipos-proyecto"
                                                    exact
                                                    className="nav-link i--h"
                                                    activeClassName={"active"}
                                                    onClick={navToggle}
                                                >
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <i className="material-icons">
                                                            assignment
                                                        </i>
                                                    </div>
                                                    <span>Tipos Proyecto</span>
                                                </NavLink>
                                            </li>
                                    </ul>
                                </div>
                            </div>
                        </li> ) : null}
                        {role == 1 || role == 10 ? (<li className="nav-item" onClick={() => this.showSublist(2)}>
                            <div className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons title--i">group</i>
                                </div>
                                <span>Recursos</span>
                                <div className="d-inline-block item-icon-wrapper ml-3">
                                    <i className="material-icons rotate" style={{color: 'black'}}>arrow_drop_down</i>
                                </div>
                                <div className="sublist" id="recurso_sublist">
                                    <ul className="nav--no-borders flex-column nav">
                                        {role == 1 || role == 10 ? (
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/recursos"
                                                    exact
                                                    className="nav-link i--h"
                                                    activeClassName={"active"}
                                                    onClick={navToggle}
                                                >
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <i className="material-icons">group</i>
                                                    </div>
                                                    <span>Listado</span>
                                                </NavLink>
                                            </li>
                                        ) : null}
                                        {role == 1 ? (
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/puestos"
                                                    exact
                                                    className="nav-link i--h"
                                                    activeClassName={"active"}
                                                    onClick={navToggle}
                                                >
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <i className="material-icons">
                                                            contact_mail
                                                        </i>
                                                    </div>
                                                    <span>Puestos</span>
                                                </NavLink>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                        </li>) : null}
                        <li className="nav-item drop--down" onClick={() => this.showSublist(3)}>
                            <div className="nav-link ">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons title--i">people_alt</i>
                                </div>
                                <span>Usuarios</span>
                                <div className="d-inline-block item-icon-wrapper ml-3">
                                    <i className="material-icons rotate" style={{color: 'black'}}>arrow_drop_down</i>
                                </div>
                                <div className="sublist" id="usuario_sublist">
                                    <ul className="nav--no-borders flex-column nav">
                                        {role == 1 ? (
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/users"
                                                    exact
                                                    className="nav-link i--h"
                                                    activeClassName={"active"}
                                                    onClick={navToggle}
                                                >
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <i className="material-icons">
                                                            people_alt
                                                        </i>
                                                    </div>
                                                    <span>Listado</span>
                                                </NavLink>
                                            </li>
                                        ) : null}
                                        <li className="nav-item">
                                            <NavLink
                                                to="/change-password"
                                                exact
                                                className="nav-link i--h"
                                                activeClassName={"active"}
                                                onClick={navToggle}
                                            >
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i className="material-icons">lock_open</i>
                                                </div>
                                                <span>Cambiar contraseña</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                to="/login"
                                                onClick={logOut}
                                                className="nav-link i--h"
                                            >
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i className="material-icons">lock</i>
                                                </div>
                                                <span>Log Out</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SideBar;
