import React, { useState } from "react";
import { exit_type } from "../../../../utility/constants";
import Select from "react-select";
import Swal from "sweetalert2";

const Eliminar = (props) => {

    const [tipo, setTipo] = useState(null);
    const [fecha, setFecha] = useState(null);
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    const eliminar = () => {
        const data = {
            "despedido": tipo == 2 ? true : false, 
            "renuncia": tipo == 1 ? true : false,
            "fecha_fin": fecha,
            id: props.id
        }
        Swal.fire({
            title: '¡Atención!',
            text: '¿Está seguro de realizar esta acción?',
            type: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
              console.log(result);
            if (result.value) {
                if(props.params)
                    props.renunciaDespido(data, props.params);
                else
                    props.renunciaDespido(data);
            }
          });
    }

    return (
        <React.Fragment>
                <div className="card">
                    <div className="card-header bg-primary text-white">Despido / Renuncia</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group has-feedback col-12">
                                <label htmlFor="tipo_contratacion">
                                    Fecha inicio labores
                                </label>
                                <input className="form-control input--style" type={"date"} onChange={(e) => setFecha(e.target.value)}/>
                                <label htmlFor="tipo_contratacion">
                                    Tipo de salida
                                </label>
                                <Select
                                    styles={select_style}
                                    name="exit_type"
                                    isClearable={true}
                                    isSearchable={true}
                                    options={exit_type}
                                    onChange={(e) => setTipo(e.value)}
                                />
                            </div>
                            <div className="col-12">
                                <button
                                    className="btn btn-secondary m-1"
                                    type="button"
                                    onClick={() => props.close(false)}
                                >
                                    Regresar
                                </button>
                                <button
                                    type="button"
                                    style={{ fontSize: 15 }}
                                    className="btn btn-primary m-1 align-self-center"
                                    disabled={!fecha || !tipo}
                                    onClick={() => eliminar()}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default Eliminar;
