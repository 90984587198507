import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login } from './common/components/Login';
import { Home } from './common/components/Home';

import { 
    Listar as ListarRecursos,
    CrearEditar as CrearEditarRecursos,
} from './common/components/Recursos';
import { Listar as ListarFasesProyecto, CrearEditar as CrearEditarFaseProyecto } from './common/components/FasesProyecto';
import { Listar as ListarTiposProyecto, CrearEditar as CrearEditarTipoProyecto } from './common/components/TipoProyecto';
import { Listar as ListarClientes, CrearEditar as CrearEditarCliente } from './common/components/Clientes';
import { Listar as ListarPuestos, CrearEditar as CrearEditarPuesto } from './common/components/Puestos';
import { Listar as ListarUsuarios, CrearEditar as CrearEditarUsuario } from './common/components/Users';
import { ChangePassword } from './common/components/Profile';
import { 
    Listar as ListarProyectos,
    CrearEditar as CrearEditarProyectos,
} from './common/components/Proyectos'
import {
    Visualizar as VisualizarGantt
} from './common/components/Gantt'
import {
    Visualizar as VisualizarResumen
} from './common/components/Resumen'

import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <ProtectedRoute access={[0]} exact path="/" component={Home} />
                <ProtectedRoute access={[0]} exact path="/change-password" component={ChangePassword} />
                <ProtectedRoute access={[1]} exact path="/users" component={ListarUsuarios} />
                <ProtectedRoute access={[1]} exact path="/users/crear" component={CrearEditarUsuario} />
                <ProtectedRoute access={[1]} exact path="/users/:id/editar" component={CrearEditarUsuario} />
                <ProtectedRoute access={[1, 10]} exact path="/recursos" component={ListarRecursos} />
                <ProtectedRoute access={[1]} exact path="/recursos/crear" component={CrearEditarRecursos} />
                <ProtectedRoute access={[1, 10]} exact path="/recursos/:id/editar" component={CrearEditarRecursos} />
                <ProtectedRoute access={[1, 10]} exact path="/proyectos" component={ListarProyectos} />
                <ProtectedRoute access={[1, 10]} exact path="/proyectos/crear" component={CrearEditarProyectos} />
                <ProtectedRoute access={[1, 10]} exact path="/proyectos/:id/editar/:tipo" component={CrearEditarProyectos} />
                <ProtectedRoute access={[0]} exact path="/gantt" component={VisualizarGantt} />
                <ProtectedRoute access={[0]} exact path="/resumen" component={VisualizarResumen} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto" component={ListarFasesProyecto} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto/crear" component={CrearEditarFaseProyecto} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto/:id/editar" component={CrearEditarFaseProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto" component={ListarTiposProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto/crear" component={CrearEditarTipoProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto/:id/editar" component={CrearEditarTipoProyecto} />
                <ProtectedRoute access={[1]} exact path="/clientes" component={ListarClientes} />
                <ProtectedRoute access={[1]} exact path="/clientes/crear" component={CrearEditarCliente} />
                <ProtectedRoute access={[1]} exact path="/clientes/:id/editar" component={CrearEditarCliente} />
                <ProtectedRoute access={[1]} exact path="/puestos" component={ListarPuestos} />
                <ProtectedRoute access={[1]} exact path="/puestos/crear" component={CrearEditarPuesto} />
                <ProtectedRoute access={[1]} exact path="/puestos/:id/editar" component={CrearEditarPuesto} />
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
