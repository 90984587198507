import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";

const SUBMIT = "RECURSO_SUBMIT";
const LOADER = "RECURSO_LOADER";
const DATA = "RECURSO_DATA";
const ALL = "RECURSO_ALL";

const ENDPOINT = "recurso";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setAll = (all) => ({
    type: ALL,
    all,
});

// ------------------------------------
// Actions
// ------------------------------------

export const renunciaDespido =
    (data = {}, params = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}/despido_renuncia`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(listar(1, params));
                dispatch(push("/recursos"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(push("/recursos"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const proyectFilter =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get("/user/me")
            .then((response) => {
                if (response.type == 10) {
                    params.encargado = response.recurso;
                }
                api.get(`${ENDPOINT}/proyect_filter`, { page, ...params })
                    .then((response) => {
                        dispatch(setData(response));
                        dispatch(setLoader(false));
                    })
                    .catch(() => {
                        dispatch(setLoader(false));
                    });
            })
            .catch(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerRecurso =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                response.puesto = response.puesto.id;
                response.proyecto_default = response.proyecto_default.id;
                response.tipo_contratacion = response.tipo_contratacion
                    ? response.tipo_contratacion.id
                    : null;
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarRecurso =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/recursos"));
            })
            .catch((err) => {
                let text = "Ha ocurrido un error, inténtelo nuevamente.";
                if(err.detail.includes("Duplicate")) {
                    text = "Código ya asignado, por favor inténtelo con otro."
                }
                Swal.fire({
                    title: "Error",
                    text,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id, params = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then((response) => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
            dispatch(listar(1, params));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getMe = () => (dispatch) => {
    api.get("/user/me")
        .then((me) => {
            dispatch(initializeForm("profile", me));
            dispatch(setMe(me));
        })
        .catch(() => {})
        .finally(() => {});
};

export const getAll = (params={}) => (dispatch) => {
    api.get(`${ENDPOINT}/all`, { ...params })
        .then((all) => {
            dispatch(setAll(all));
        })
        .catch(() => {})
        .finally(() => {});
};

export const actions = {
    listar,
    eliminar,
    onSubmit,
    obtenerRecurso,
    actualizarRecurso,
    getAll,
    proyectFilter,
    renunciaDespido
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ALL]: (state, { all }) => {
        return {
            ...state,
            all,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    all: [],
};

export default handleActions(reducers, initialState);
