import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";

const CrearRecurso = (props) => {
    //Variables
    const [recurso, setRecurso] = useState({ validado: true });
    const [update_recurso, setUpdateRecurso] = useState(false);

    useEffect(() => {
        if (props.edit_recurso && !update_recurso) {
            setRecurso(props.edit_recurso);
            setUpdateRecurso(true);
        }
    });

    const swalError = (msg) => {
        Swal.fire({
            title: "¡Atención!",
            text: msg,
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
        });
    };

    //Funciones
    const fillResource = () => {
        const errores = [
            "Por favor, llene todos los campos:",
            "El porcentaje debe ser mayor a 0.",
            "El porcentaje debe ser menor a 100.",
        ];
        const fecha_inicio = document.getElementById("fecha_inicio");
        const fecha_fin = document.getElementById("fecha_fin");
        const recurso_copy = { ...recurso };
        recurso_copy["porcentaje_asignacion"] = props.porcentaje;
        recurso_copy["fecha_inicio"] = fecha_inicio.value;
        recurso_copy["fecha_fin"] = fecha_fin.value;
        
        if (
            !recurso_copy.fecha_inicio ||
            !recurso_copy.fecha_fin ||
            !recurso_copy.fase_nombre ||
            !recurso_copy.fase_proyecto ||
            !recurso_copy.porcentaje_asignacion ||
            !recurso_copy.puesto ||
            !recurso_copy.puesto_label
        ) {
            let message = errores[0];
            message += !recurso_copy.fecha_inicio ? ' Fecha inicio,' : '';
            message += !recurso_copy.fecha_fin ? ' Fecha fin,' : '';
            message += !recurso_copy.fase_proyecto ? ' Fase,' : '';
            message += !recurso_copy.porcentaje_asignacion ? ' Porcentaje,' : '';
            message += !recurso_copy.puesto ? 'Puesto,' : '';
            message = message.slice(0, -1);
            message += '.';
            swalError(message);
        } else if (props.porcentaje <= 0) {
            swalError(errores[1]);
        } else if (props.porcentaje > 100) {
            swalError(errores[2]);
        } else {
            props.updateArray(recurso_copy, props.edit_index);
            fecha_inicio.value = null;
            fecha_fin.value = null;
            props.setPorcentaje("");
            props.setFase(null);
            props.setPersona(null);
            props.setTipo(null);
            props.setEditIndex(null);
            setRecurso({ validado: true });
            setUpdateRecurso(false);
            props.setEditRecurso(null);
        }
    };

    const cancelEdit = () => {
        document.getElementById("fecha_inicio").value = null;
        document.getElementById("fecha_fin").value = null;
        props.setPorcentaje("");
        props.setFase(null);
        props.setPersona(null);
        props.setTipo(null);
        props.setEditIndex(null);
    };

    const fillSelect = (key, key1, id, label) => {
        const recurso_copy = { ...recurso };
        recurso_copy[key] = id;
        recurso_copy[key1] = label;
        setRecurso(recurso_copy);
    };

    //Html
    return (
        <div className="row mt-3 mb-3">
            <div className="form-group has-feedback col">
                <label style={{ display: "none" }}>{props.update}</label>
                <label htmlFor="fase_proyecto">Fase</label>
                <Select
                    styles={props.select_style}
                    className="input--style"
                    name="fase_proyecto"
                    isClearable={false}
                    isSearchable={true}
                    options={props._fases}
                    onChange={(e) => {
                        props.setFase(e);
                        fillSelect(
                            "fase_proyecto",
                            "fase_nombre",
                            e.value,
                            e.label
                        );
                    }}
                    value={props.fase_proyecto}
                />
            </div>

            <div className="form-group has-feedback col">
                <label htmlFor="puesto">Recurso</label>
                <Select
                    styles={props.select_style}
                    name="recurso"
                    className="input--style"
                    isClearable={false}
                    isSearchable={true}
                    options={props._recursos}
                    onChange={(e) => {
                        props.setPersona(e);
                        fillSelect(
                            "recurso",
                            "recurso_nombre",
                            e.value,
                            e.label
                        );
                    }}
                    value={props.persona}
                />
            </div>
            <div className="form-group has-feedback col-1">
                <label htmlFor="puesto">Porcentaje</label>
                <input
                    name={`porcentaje_asignacion`}
                    id={`porcentaje_asignacion`}
                    className="form-control input--style"
                    type="number"
                    placeholder="0"
                    onChange={(e) => props.setPorcentaje(e.target.value)}
                    value={props.porcentaje}
                />
            </div>
            <div className="form-group has-feedback col">
                <label htmlFor="tipo_contratacion">Fecha de inicio</label>
                <input
                    name={`fecha_inicio`}
                    id={`fecha_inicio`}
                    className="form-control input--style"
                    type="date"
                />
            </div>
            <div className="form-group has-feedback col">
                <label htmlFor="tipo_contratacion">Fecha final</label>
                <input
                    name={`fecha_fin`}
                    id={`fecha_fin`}
                    className="form-control input--style"
                    type="date"
                />
            </div>
            <div className="form-group has-feedback col">
                <label htmlFor="puesto">Tipo</label>
                <Select
                    styles={props.select_style}
                    name="tipo"
                    className="input--style"
                    isClearable={false}
                    isSearchable={true}
                    options={props._tipos}
                    onChange={(e) => {
                        props.setTipo(e);
                        fillSelect("puesto", "puesto_label", e.value, e.label);
                    }}
                    value={props.tipo}
                />
            </div>
            <div
                className="form-group has-feedback col"
                style={{ display: "flex", alignItems: "flex-end" }}
            >
                {props.edit_index ? (
                    <button
                        onClick={() => cancelEdit()}
                        style={{ fontSize: 15, marginRight: "5px" }}
                        className="btn btn-danger"
                        type="button"
                    >
                        Cancelar
                    </button>
                ) : null}
                <button
                    onClick={() => fillResource()}
                    style={{ fontSize: 15 }}
                    className={`btn ${
                        props.edit_index ? "btn-info" : "add--button"
                    }`}
                    type="button"
                >
                    {props.edit_index ? "Actualizar" : "Agregar"}
                </button>
            </div>
        </div>
    );
};

export default CrearRecurso;