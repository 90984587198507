export const user_types = [
    {
        label: 'Administrador',
        value: 1
    },
    {
        label: 'Vendedor',
        value: 5
    },
    {
        label: 'SCRUM Master',
        value: 10
    }
];

export const exit_type = [
    {
        label: "Renuncia",
        value: 1
    },
    {
        label: "Despido",
        value: 2
    }
];

export const items_page = [
    {
        label: '10',
        value: '10'
    },
    {
        label: '25',
        value: '25'
    },
    {
        label: '50',
        value: '50'
    },
];