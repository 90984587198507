import React from "react";
import { Chart, registerables } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';

Chart.register(...registerables);

const Recursos = (props) => {
    
    let data_struct = null;
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'center'
            },
            title: {
                display: false,
                text: ''
            },
            subtitle: {
                display: false,
                text: 'Custom Chart Subtitle'
            }
        },
    };
    if(props.data) {
        data_struct = props.data.chart;
    }

    return (
        <React.Fragment>
            <div className="col-12 col-md-6" style={props.align}>
                <div className="card mb-3 mt-3 recurso--card" style={{boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)"}}>
                    <div className="card-header card--header">
                        <h6 style={{color: 'white', fontWeight: 'bold'}}>{props.title}</h6>
                    </div>
                    <div className="card-body">
                        {Math.max(...data_struct.datasets[0].data) > 0 ? <Doughnut data={data_struct} options={options} width={100} height={100} /> : <img
                                    style={{width: "100%", height: '100%', margin: '8% 0'}}
                                    className="d-inline-block align-top mr-1"
                                    src={require("assets/img/empty_doughnut.png")}
                                    alt="Logo"
                                />}
                        <hr style={{backgroundColor: "gray"}}/>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <h6 className="text--blue--1"><strong style={{fontWeight: 'bold'}}>{props.data.total.cantidad}</strong> personas fijas</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 justify-content-center">
                                <h6 className="text--blue--1"><strong style={{fontWeight: 'bold'}}>{props.data.despedidos.cantidad}</strong> Despedidos</h6>
                            </div>
                            <div className="col-12 col-md-6 justify-content-center">
                                <h6 className="text--blue--1"><strong style={{fontWeight: 'bold'}}>{props.data.renuncias.cantidad}</strong> Renuncias</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Recursos;
