import moment from "moment";
import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select from "react-select";
import { api } from "api";
import { DateRange } from "react-date-range";
import locale from "date-fns/locale/es";
import Popup from "reactjs-popup";
import { NavLink } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../../../../style/custom.css";
import "./resumen.css";

class Visualizar extends Component {
    state = {
        hide: {},
        vistas: [
            { value: "Day", label: "Día" },
            { value: "Week", label: "Semana" },
            { value: "Month", label: "Mes" },
        ],
        vista_por: {
            value: "Week",
            label: "Semanas",
        },
        puesto: 1,
        recursos: [],
        tipos_proyecto: [],
        sticky_filters: false,
        filters: {
            fechas_range: null, // PARA FILTRAR POR FECHAS DEL PROYECTO
            proyectos__recursos__recurso__id: null, // PARA FILTRAR POR RECURSO
            proyectos__encargado__id: null, // PARA FILTRAR POR ENCARGADO
            id: null, // PARA FILTRAR POR TIPO DE PROYECTO
            puesto: 0,
        },
        loader: true,
        popup_dates_open: false,
        date_range: {},
        popup_ocupado: false,
        popup_ocupado_data: {
            personas: [],
        },
        indices: 0,
        orden_nombre: true,
        orden_proyecto: false,
        actual: 'nombre'
    };

    componentDidMount() {
        this.props.filterStalls();
        api.get("recurso", { activo: true }).then((response) => {
            response.results.forEach((result) => {
                result.value = result.id;
                result.label = result.nombre;
            });
            this.setState({ recursos: response.results });
            api.get("tipo_proyecto", { activo: true }).then((response) => {
                response.results.forEach((result) => {
                    result.value = result.id;
                    result.label = result.nombre;
                });
                this.setState({
                    tipos_proyecto: response.results,
                    loader: false,
                });
                /* 
                    SE CALCULAN LAS FECHAS INICIALES
                    RESPECTO A LA ACTUAL UN MES ANTES PARA 4 ADELANTE
                */
                const inicio = moment().subtract(2, "weeks");
                const fin = moment().add(4, "months");
                const fechas_range = [
                    inicio.format("YYYY-MM-DD"),
                    fin.format("YYYY-MM-DD"),
                ];
                this.setState({
                    date_range: {
                        startDate: new Date(inicio),
                        endDate: new Date(fin),
                        key: "selection",
                    },
                    fechas_range,
                    filters: {
                        ...this.state.filters,
                        fechas_range,
                    },
                });
                //this.props.getAllData({ fechas_range })
            });
        });
    }

    sortPersonas(factor) {
        const copy = this.state.popup_ocupado_data;
        if(factor == 'nombre') {
            const valor = !this.state.orden_nombre;
            if(valor) copy.personas.sort((a, b) => (a.nombre > b.nombre) ? 1 : (a.nombre === b.nombre) ? ((a.nombre < b.nombre) ? 1 : -1) : -1)
            else copy.personas.sort((a, b) => (a.nombre < b.nombre) ? 1 : (a.nombre === b.nombre) ? ((a.nombre > b.nombre) ? 1 : -1) : -1) 
            this.setState({ popup_ocupado_data: copy, orden_nombre: valor, actual: factor });
        } else {
            const valor = !this.state.orden_proyecto;
            if(valor) copy.personas.sort((a, b) => (a.proyecto > b.proyecto) ? 1 : (a.proyecto === b.proyecto) ? ((a.proyecto < b.proyecto) ? 1 : -1) : -1)
            else copy.personas.sort((a, b) => (a.proyecto < b.proyecto) ? 1 : (a.proyecto === b.proyecto) ? ((a.proyecto > b.proyecto) ? 1 : -1) : -1)
            this.setState({ popup_ocupado_data: copy, orden_proyecto: valor, actual: factor });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters != this.state.filters) {
            const params = {};
            Object.keys(this.state.filters).forEach((key) => {
                if (this.state.filters[key] !== null) {
                    params[key] =
                        this.state.filters[key].value ||
                        this.state.filters[key].value == 0
                            ? this.state.filters[key].value
                            : this.state.filters[key];
                }
            });
            this.props.getAllData(params);
        }
    }

    handleSelect = (range) => {
        const inicio = range.selection.startDate,
            fin = range.selection.endDate;
        this.setState({
            date_range: {
                startDate: inicio,
                endDate: fin,
                key: range.selection.key,
            },
            filters: {
                ...this.state.filters,
                fechas_range: [
                    moment(inicio).format("YYYY-MM-DD"),
                    moment(fin).format("YYYY-MM-DD"),
                ],
            },
        });
    };

    closeDatePopup = () => {
        this.setState({ popup_dates_open: false });
    };

    render() {
        const { data, scrumData, loader, semanas, muestra, fases } = this.props;
        const FILTER_STALLS = [{ id: 0, value: 0, label: "Todos" }].concat(
            this.props.puestos.filter
        );
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };
        const total_label_class = "text-left pr-4 position--thtd";
        const total_label_style = {
            minWidth: "200px",
            left: "248px",
            backgroundColor: "white",
            borderRight: "1px solid #979797",
        };
        const actual_week = moment()
            .subtract(moment().day() - (moment().day() == 0 ? -6 : 1), "days")
            .format("YYYY-MM-DD");
        let indice = 0;
        let show = true;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col text-left">
                        <h2 className="title--blue">RESUMEN</h2>
                    </div>
                </div>
                <hr />
                {/* SECCION DE FILTROS */}
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-4 ml-2">
                                <label htmlFor="puesto">Puesto</label>
                                <Select
                                    styles={select_style}
                                    name="puesto"
                                    isClearable={true}
                                    isSearchable={true}
                                    options={FILTER_STALLS}
                                    onChange={(e) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                puesto: e,
                                            },
                                        });
                                    }}
                                    defaultValue={FILTER_STALLS[0]}
                                />
                            </div>
                            <div className="col-12 col-md-2 ml-2">
                                <label htmlFor="puesto">Fechas</label>
                                <br />
                                <button
                                    className="btn btn-light button--primary"
                                    onClick={() => {
                                        this.setState({
                                            popup_dates_open: true,
                                        });
                                    }}
                                >
                                    {moment(
                                        this.state.date_range.startDate
                                    ).format("DD/MM/YYYY")}
                                    &nbsp;-&nbsp;
                                    {moment(
                                        this.state.date_range.endDate
                                    ).format("DD/MM/YYYY")}
                                </button>
                                <Popup
                                    open={this.state.popup_dates_open}
                                    onClose={this.closeDatePopup}
                                    modal
                                    repositionOnResize={true}
                                >
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-center">
                                                <DateRange
                                                    editableDateInputs={true}
                                                    ranges={[this.state.date_range]}
                                                    onChange={this.handleSelect}
                                                    locale={locale}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                                {/* POPUP DE LOS NOMBRES */}
                                <Popup
                                    open={this.state.popup_ocupado}
                                    onClose={() =>
                                        this.setState({ popup_ocupado: false, orden_nombre: true, orden_proyecto: false, actual: 'nombre' })
                                    }
                                >
                                    <React.Fragment>
                                        <div
                                            style={{
                                                overflowY: "scroll",
                                                maxHeight: "400px",
                                            }}
                                        >
                                            <div className="card-header card--header">
                                                {
                                                    this.state
                                                        .popup_ocupado_data
                                                        .action
                                                }
                                            </div>
                                            <div className="card-header card--header--1 mt-3">
                                                SEMANA:&nbsp;
                                                {moment(
                                                    this.state
                                                        .popup_ocupado_data
                                                        .semana
                                                ).format("DD/MM/YYYY")}
                                            </div>
                                            <div className="row">
                                                <div className="col-1" />
                                                <div className="col">
                                                    {this.state.popup_ocupado_data.scrum ? (this.state.popup_ocupado_data.personas.length > 0 ? (
                                                            <table className="table table-bordered mt-3 text--blue--1">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col" style={{cursor: 'pointer', display: "flex", justifyContent: "space-between"}} onClick={() => this.sortPersonas('nombre')}>Nombre {this.state.actual != 'nombre' ? <i className="material-icons" style={{opacity: '0.5'}}>unfold_more</i> : this.state.orden_nombre ? <i style={{top: "-2px"}} className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.popup_ocupado_data.personas.map((persona, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th scope="row">{index + 1}</th>
                                                                                    <td>
                                                                                        <NavLink className="nav--link--color" to={"/proyectos/" + persona.id + "/editar/recursos" } exact>
                                                                                            { persona.nombre }
                                                                                        </NavLink>
                                                                                    </td>
                                                                                </tr>
                                                                            )})}
                                                                </tbody>
                                                            </table>
                                                        ) : (<h5 className="mt-3"> No hay datos.</h5>)
                                                    ) : this.state.popup_ocupado_data.personas.length > 0 ? (
                                                        <table className="table table-bordered mt-3 text--blue--1">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col"><div style={{cursor: 'pointer', display: "flex", justifyContent: "space-between"}} onClick={() => this.sortPersonas('nombre')}>Nombre {this.state.actual != 'nombre' ? <i className="material-icons" style={{opacity: '0.5'}}>unfold_more</i> : this.state.orden_nombre ? <i style={{top: "-2px"}} className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}</div></th>
                                                                    {this.state.popup_ocupado_data.type == 1 || this.state.popup_ocupado_data.type == 2 ? (
                                                                        <th scope="col">%</th>
                                                                    ) : null}
                                                                    {this.state.popup_ocupado_data.type == 1 || this.state.popup_ocupado_data.type == 4 ? (
                                                                        <th scope="col"><div style={{cursor: 'pointer', display: "flex", justifyContent: "space-between"}} onClick={() => this.sortPersonas('proyecto')}>Proyecto {this.state.actual != 'proyecto' ? <i className="material-icons" style={{opacity: '0.5'}}>unfold_more</i> : this.state.orden_proyecto ? <i style={{top: "-2px"}} className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}</div></th>
                                                                    ) : null}
                                                                    {this.state.popup_ocupado_data.type == 2 ? (
                                                                        <th scope="col"><div style={{cursor: 'pointer', display: "flex", justifyContent: "space-between"}} onClick={() => this.sortPersonas('proyecto')}>Último proyecto {this.state.actual != 'proyecto' ? <i className="material-icons" style={{opacity: '0.5'}}>unfold_more</i> : this.state.orden_proyecto ? <i style={{top: "-2px"}} className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}</div></th>
                                                                    ) : null}
                                                                    {this.state.popup_ocupado_data.type == 2 ? (
                                                                        <th scope="col">Fecha fin</th>
                                                                    ) : null}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.popup_ocupado_data.personas.map((persona, index) => {
                                                                        if (index == 0 ) {
                                                                            indice = index;
                                                                        } else {
                                                                            if (this.state.popup_ocupado_data.personas[index - 1].nombre !=persona.nombre) {
                                                                                indice += 1;
                                                                                show = true;
                                                                            } else {
                                                                                show = false;
                                                                            }
                                                                        }
                                                                        return (
                                                                            <tr key={index}>
                                                                                <th scope="row">
                                                                                    {show ? indice + 1 : null}
                                                                                </th>
                                                                                <td> 
                                                                                    {show ? (
                                                                                        <NavLink className="nav--link--color" to={"/recursos/" + persona.id_recurso + "/editar"} exact >
                                                                                            {persona.nombre}
                                                                                        </NavLink>
                                                                                    ) : null}
                                                                                </td>
                                                                                {this.state.popup_ocupado_data.type == 1 || this.state.popup_ocupado_data.type == 2 ? (
                                                                                    <td>
                                                                                        { persona.porcentaje }
                                                                                    </td>
                                                                                ) : null}
                                                                                {this.state.popup_ocupado_data.type == 1 || this.state.popup_ocupado_data.type == 2 || this.state.popup_ocupado_data.type == 4 ? (
                                                                                    <td>
                                                                                        { persona.proyecto ? <NavLink className="nav--link--color" to={"/proyectos/" + persona.proyecto_id + "/editar/recursos"} exact>
                                                                                            { persona.proyecto }
                                                                                        </NavLink> : 'Sin proyecto asignado'}
                                                                                    </td>
                                                                                ) : null}
                                                                                {this.state.popup_ocupado_data.type == 2 ? (
                                                                                    <td>
                                                                                        { persona.fecha_fin ? moment(persona.fecha_fin).format("DD/MM/YYYY") : 'Sin asignación' }
                                                                                    </td>
                                                                                ) : null}
                                                                            </tr>)})}
                                                            </tbody>
                                                        </table>
                                                    ) : (<h5 className="mt-3">No hay datos.</h5>)}
                                                </div>
                                                <div className="col-1" />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </Popup>
                            </div>
                        </div>
                    </div>
                </div>
                {/* INICIO DEL RESUMEN */}
                {/* TOTALES TEMP */}
                <LoadMask loading={loader || this.state.loader} light>
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            Ruta de Trabajo
                        </div>
                        <div className="card-body">
                            <div class="">
                            <div className="tabla-resumen table--scroll">
                                <table className="mr-4 mb-3">
                                    <thead>
                                        <tr className="p-2 tr--1">
                                            <th
                                                style={{
                                                    minWidth: "200px",
                                                    left: "0px",
                                                    backgroundColor: "white",
                                                    borderRight:
                                                        "1px solid #979797",
                                                }}
                                                className="position--thtd"
                                                colSpan={2}
                                            ></th>
                                            {semanas.map((semana, index) => {
                                                return (
                                                    <th
                                                        className="text-center"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflowX: "auto",
                                                            padding: "10px",
                                                            backgroundColor:
                                                                semana ==
                                                                actual_week
                                                                    ? "rgba(31, 77, 123, 0.3)"
                                                                    : "",
                                                            color:
                                                                semana <
                                                                actual_week
                                                                    ? "rgba(31, 77, 123, 0.3)"
                                                                    : "",
                                                            border: "1px solid rgb(151, 151, 151)",
                                                            borderTop: "none",
                                                        }}
                                                        key={index}
                                                    >
                                                        {moment(semana).format(
                                                            "DD/MM/YYYY"
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="p-2 tr--1">
                                            <th
                                                colSpan={2}
                                                style={{
                                                    minWidth: "200px",
                                                    left: "0px",
                                                    backgroundColor: "white",
                                                    borderRight:
                                                        "1px solid #979797",
                                                }}
                                                className={total_label_class}
                                            >
                                                Tipo de proyecto
                                            </th>
                                            {semanas.map((semana, index) => {
                                                return (
                                                    <td
                                                        key={index}
                                                        style={{
                                                            border: "1px solid #979797",
                                                        }}
                                                    >
                                                        {muestra == 0 ||
                                                        muestra == 2 ? (
                                                            <td
                                                                width="110"
                                                                className="text-center"
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflowX:
                                                                        "auto",
                                                                    backgroundColor:
                                                                        semana ==
                                                                        actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : "",
                                                                    color:
                                                                        semana <
                                                                        actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : "",
                                                                }}
                                                            >
                                                                P
                                                            </td>
                                                        ) : null}
                                                        {muestra == 0 ||
                                                        muestra == 3 ? (
                                                            <td
                                                                width="110"
                                                                className="text-center"
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflowX:
                                                                        "auto",
                                                                    backgroundColor:
                                                                        semana ==
                                                                        actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : "",
                                                                    color:
                                                                        semana <
                                                                        actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : "",
                                                                }}
                                                            >
                                                                D
                                                            </td>
                                                        ) : null}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {data.map((tipo_proyecto) => {
                                            return (
                                                <React.Fragment
                                                    key={tipo_proyecto.id}
                                                >
                                                    <tr className="p-2">
                                                        <th
                                                            className="text-left position--thtd"
                                                            width="250"
                                                            style={{
                                                                minWidth:
                                                                    "250px",
                                                                left: "0px",
                                                                backgroundColor:
                                                                    "white",
                                                                borderRight:
                                                                    "1px solid #979797",
                                                            }}
                                                            rowSpan={4}
                                                        >
                                                            {
                                                                tipo_proyecto.nombre
                                                            }
                                                        </th>
                                                        <th
                                                            style={
                                                                total_label_style
                                                            }
                                                            className={
                                                                total_label_class
                                                            }
                                                        >
                                                            OCUPADO
                                                        </th>
                                                        {tipo_proyecto.total_ocupado.map(
                                                            (
                                                                ocupado,
                                                                index
                                                            ) => {
                                                                const cell_color_p =
                                                                    tipo_proyecto.p_color
                                                                        ? tipo_proyecto
                                                                              .total_sin_ocupar[
                                                                              index
                                                                          ]
                                                                              .cantidad_programadores ==
                                                                          0
                                                                            ? "#ACD89A"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                const cell_color_d =
                                                                    tipo_proyecto.d_color
                                                                        ? tipo_proyecto
                                                                              .total_sin_ocupar[
                                                                              index
                                                                          ]
                                                                              .cantidad_disenadores ==
                                                                          0
                                                                            ? "#ACD89A"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                return (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            border: "1px solid #979797",
                                                                        }}
                                                                    >
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            2 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_p,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        ocupado.recursos_programadores,
                                                                                                    action: "PROGRAMADORES OCUPADOS",
                                                                                                    type: 1,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    ocupado.cantidad_programadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            3 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_d,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        ocupado.recursos_disenadores,
                                                                                                    action: "DISEÑADORES OCUPADOS",
                                                                                                    type: 1,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    ocupado.cantidad_disenadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            style={
                                                                total_label_style
                                                            }
                                                            className={
                                                                total_label_class
                                                            }
                                                        >
                                                            DISPONIBLE
                                                        </th>
                                                        {tipo_proyecto.total_sin_ocupar.map(
                                                            (
                                                                sin_ocupar,
                                                                index
                                                            ) => {
                                                                const cell_color_p =
                                                                    tipo_proyecto.p_color
                                                                        ? sin_ocupar.cantidad_programadores >
                                                                          0
                                                                            ? "#D78888"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                const cell_color_d =
                                                                    tipo_proyecto.d_color
                                                                        ? sin_ocupar.cantidad_disenadores >
                                                                          0
                                                                            ? "#D78888"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                return (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            border: "1px solid #979797",
                                                                        }}
                                                                    >
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            2 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_p,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        sin_ocupar.recursos_programadores,
                                                                                                    action: "PROGRAMADORES DISPONIBLES",
                                                                                                    type: 2,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    sin_ocupar.cantidad_programadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            3 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_d,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        sin_ocupar.recursos_disenadores,
                                                                                                    action: "DISEÑADORES DISPONIBLES",
                                                                                                    type: 2,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    sin_ocupar.cantidad_disenadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            style={
                                                                total_label_style
                                                            }
                                                            className={
                                                                total_label_class
                                                            }
                                                        >
                                                            CONTRATADO
                                                        </th>
                                                        {tipo_proyecto.total_contratado.map(
                                                            (
                                                                contratado,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            border: "1px solid #979797",
                                                                        }}
                                                                    >
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            2 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] ==
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        contratado.recursos_programadores,
                                                                                                    action: "PROGRAMADORES ACTIVOS",
                                                                                                    type: 3,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    contratado.cantidad_programadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            3 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] ==
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        contratado.recursos_disenadores,
                                                                                                    action: "DISEÑADORES ACTIVOS",
                                                                                                    type: 3,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    contratado.cantidad_disenadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                    <tr className="tr--1">
                                                        <th
                                                            style={
                                                                total_label_style
                                                            }
                                                            className={
                                                                total_label_class
                                                            }
                                                        >
                                                            NO CONTRATADO
                                                        </th>
                                                        {tipo_proyecto.total_sin_contratar.map(
                                                            (
                                                                sin_contratar,
                                                                index
                                                            ) => {
                                                                const cell_color_p =
                                                                    tipo_proyecto.p_color
                                                                        ? sin_contratar.cantidad_programadores >
                                                                          0
                                                                            ? "#DD9E4F"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                const cell_color_d =
                                                                    tipo_proyecto.d_color
                                                                        ? sin_contratar.cantidad_disenadores >
                                                                          0
                                                                            ? "#DD9E4F"
                                                                            : tipo_proyecto
                                                                                  .semanas[
                                                                                  index
                                                                              ] ==
                                                                              actual_week
                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                            : ""
                                                                        : tipo_proyecto
                                                                              .semanas[
                                                                              index
                                                                          ] ==
                                                                          actual_week
                                                                        ? "rgba(31, 77, 123, 0.3)"
                                                                        : "";
                                                                return (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            border: "1px solid #979797",
                                                                        }}
                                                                    >
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            2 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_p,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        sin_contratar.recursos_programadores,
                                                                                                    action: "PROGRAMADORES NO CONTRATADOS",
                                                                                                    type: 4,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    sin_contratar.cantidad_programadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                        {muestra ==
                                                                            0 ||
                                                                        muestra ==
                                                                            3 ? (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        cell_color_d,
                                                                                    color:
                                                                                        tipo_proyecto
                                                                                            .semanas[
                                                                                            index
                                                                                        ] <
                                                                                        actual_week
                                                                                            ? "rgba(31, 77, 123, 0.3)"
                                                                                            : "",
                                                                                }}
                                                                                width={
                                                                                    muestra ==
                                                                                    0
                                                                                        ? "55"
                                                                                        : "110"
                                                                                }
                                                                                className="text-center"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            popup_ocupado: true,
                                                                                            popup_ocupado_data:
                                                                                                {
                                                                                                    semana: tipo_proyecto
                                                                                                        .semanas[
                                                                                                        index
                                                                                                    ],
                                                                                                    personas:
                                                                                                        sin_contratar.recursos_disenadores,
                                                                                                    action: "DISEÑADORES NO CONTRATADOS",
                                                                                                    type: 4,
                                                                                                },
                                                                                            indices: 0,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    sin_contratar.cantidad_disenadores
                                                                                }
                                                                            </td>
                                                                        ) : null}
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* RESUMEN SCRUMS */}
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            SCRUM Masters
                        </div>
                        <div className="card-body">
                            <div className="tabla-resumen table--scroll">
                                <table className="mr-4 mb-3">
                                    <thead>
                                        <tr>
                                            <td className="position--thtd" style={{minWidth: "350px", left: "0px", border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none', backgroundColor: 'white' }} />
                                            {semanas.map((semana) => {
                                                return (
                                                    <th key={semana} className="pl-2 pr-2 text-center all--borders" style={{padding: "15px", border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none', backgroundColor: semana == actual_week ? "rgba(31, 77, 123, 0.3)" : "", color: semana < actual_week ? "rgba(31, 77, 123, 0.3)" : "", }}>
                                                        {moment(semana).format("DD/MM/YYYY")}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-left pr-4 position--thtd" width="350" style={{minWidth: "350px", left: "0px", backgroundColor: "white", border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none', fontWeight: 'bold'}}>
                                                Nombre
                                            </td>
                                            {semanas.map((semana) => {
                                                return (
                                                    <td key={`DESC-${semana}`} style={{ border: "2px solid #979797", backgroundColor: semana == actual_week ? "rgba(31, 77, 123, 0.3)" : "", color: semana < actual_week ? "rgba(31, 77, 123, 0.3)" : "", border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none' }}>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                            {fases.map((g, i) => (
                                                                <div key={i} className="pl-2 pr-2 text-center" width="80" >
                                                                    {g.abr}
                                                                </div>
                                                            ))}
                                                            <div className="pl-2 pr-2 text-center" width="80" >
                                                                TOTAL
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {scrumData.map((scrum, index) => {
                                            return (
                                                <tr key={scrum.id} className="p-2">
                                                    <td id={"th_scrum" + index} className="text-left pr-4 position--thtd" width="350" style={{ minWidth: "350px", left: "0px", backgroundColor: "white", border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none', fontWeight: 'bold' }} >
                                                        {scrum.nombre}
                                                    </td>
                                                    {scrum.semanas_arr.map((semana) => {
                                                        return (
                                                            <td key={`CANT-${semana}`} style={{border: "1.5px solid #979797", borderLeft: "none", borderTop: 'none', backgroundColor: semana == actual_week ? "rgba(31, 77, 123, 0.3)" : "", color: semana < actual_week ? "rgba(31, 77, 123, 0.3)" : "", }} >
                                                               
                                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                                                    {fases.map((h, j) => (
                                                                        <div className="text-center" width="80" key={j} onClick={() => { this.setState({ popup_ocupado: true, popup_ocupado_data: { scrum: true, semana: semana, personas: scrum.semanas[semana].proyectos[h.nombre], action: `${h.nombre_mayus} - ${scrum.nombre}`, }, indices: 0, } ); }}>
                                                                            {scrum.semanas[semana] ? scrum.semanas[semana].proyectos[h.nombre].length : 0}
                                                                        </div>
                                                                    ))}
                                                                    <div style={{height: "50px", }} className="pl-2 pr-2 text-center" width="80" onClick={() => { this.setState({ popup_ocupado: true, popup_ocupado_data: {scrum: true, semana: semana, personas: scrum.semanas[semana].proyectos.total, action: `TOTAL - ${scrum.nombre}`,}, indices: 0,}); }} >
                                                                        {scrum.semanas[semana] ? scrum.semanas[semana].proyectos.total.length : 0}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </LoadMask>
            </React.Fragment>
        );
    }
}

export default Visualizar;
