import moment from "moment";
import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import Select from "react-select";
import '../../Recursos/Listar/Listado.css';

class Listar extends Component {
    state = {
        page: 1,
        tags: "",
        search: "",
        BreakLineCenter: {
            whiteSpace: "normal",
            textAlign: "center",
        },
        BreakLine: {
            whiteSpace: "normal",
        },
        AlignCenter: {
            textAlign: "center",
        },
        params: {
            activo: true,
            finalizado: false,
            encargado__id: null
        },
    };

    componentDidMount() {
        this.props.getClientes();
        this.props.getScrums({activo: true, despedido: false, renuncia: false});
        this.props.getRole();
        this.props.listar(this.state.page, this.state.params);
    }

    onPageChange = (page) => {
        const params = { ...this.state.params, search: this.state.search };
        if (this.state.search == "") delete params.search;
        this.props.listar(page, params);
        this.setState({ page });
    };

    radioChange = (params) => {
        params = {encargado__id: this.state.params.encargado__id, ...params}
        const params1 = { ...params, search: this.state.search };
        if (this.state.search == "") delete params1.search;
        this.setState({ params });
        this.setState({ page: 1 });
        this.props.listar(1, params1);
    };

    render() {
        const { data, loader, eliminar  } = this.props;
        const clientes = this.props.clientes.all_clients;
        const recursos = this.props.recursos.all;
        const _options = [];
        if(recursos) {
            const recursos_filter = recursos.filter((recurso) => recurso.puesto.nombre.toUpperCase().includes("SCRUM"));
            recursos_filter.forEach(option => {
                _options.push({...option, label: option['nombre'], value: option['id']});
            });
        }
        const role = this.props.user.role;
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };

        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE PROYECTOS</h2>
                    </div>
                </div>
                <hr />
                {role == 1 ? ( 
                    <div className="row">
                        <div className="col-12 col-md-6"/>
                        <div className="col-12 col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                            <Link
                                to="/proyectos/crear"
                                className="btn add--button mt-4"
                                style={{ fontSize: 15 }}
                            >
                                Nuevo proyecto
                            </Link>
                        </div>
                    </div>
                ) : null}
                    <div className="flex row mt-4">
                        <div className="col-12 col-md-4">
                        <fieldset id="proyectos" className="display--fieldset">
                            <label className="container--1">Activos
                                <input
                                    type="radio"
                                    value="true"
                                    name="proyectos"
                                    className="input--style"
                                    checked={
                                        this.state.params.activo &&
                                        !this.state.params.finalizado
                                    }
                                    onChange={() =>
                                        this.radioChange({
                                            activo: true,
                                            finalizado: false,
                                        })
                                    }/>
                                <span className="checkmark"></span>
                            </label>
                            <label className="container--1">Finalizados
                                <input
                                    type="radio"
                                    value="false"
                                    name="proyectos"
                                    style={{ marginLeft: "5px" }}
                                    checked={this.state.params.finalizado == true}
                                    onChange={() =>
                                        this.radioChange({
                                            finalizado: true,
                                        })
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="container--1">Eliminados
                                <input
                                    type="radio"
                                    value="false"
                                    name="proyectos"
                                    style={{ marginLeft: "5px" }}
                                    checked={this.state.params.activo == false}
                                    onChange={() =>
                                        this.radioChange({
                                            activo: false,
                                        })
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-md-6" style={{ display: "flex" }}>
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control input--style"
                            value={this.state.search}
                            onKeyPress={(key) => {
                                if (key.key == "Enter") {
                                    const { search, params } = this.state;
                                    this.props.listar(1, { ...params, search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                float: "right",
                            }}
                        />
                    </div>
                    {role == 1 ? 
                        <React.Fragment>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="scrum"
                                    placeholder="Scrum Master"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={_options}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if(e) {
                                            params.encargado__id = e.value;
                                        } else {
                                            delete params.encargado__id;
                                        }
                                        this.setState({params});
                                        this.props.listar(1, { ...params, search });
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="clientes"
                                    placeholder="Cliente"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={clientes}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if(e) {
                                            params.cliente__id = e.value;
                                        } else {
                                            delete params.cliente__id;
                                        }
                                        this.setState({params});
                                        this.props.listar(1, { ...params, search });
                                    }}
                                /> 
                            </div>
                        </React.Fragment> : null}
                </div>
                    <Grid
                        trClassName="td--individual border--lr border--last"
                        data={data}
                        loading={loader}
                        page={this.state.page}
                        onPageChange={this.onPageChange}
                    >
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            tdStyle={this.state.BreakLine}
                            dataFormat={(cell, row) => {
                                return cell ? <span>{cell}</span> : "---";
                            }}
                            className="thead--blue--individual"
                        >
                            Proyecto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_proyecto"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                            tdStyle={this.state.BreakLine}
                            className="thead--blue--individual"
                        >
                            Tipo de Proyecto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell
                                    ? moment(cell).format("DD/MM/YYYY")
                                    : "";
                            }}
                            className="thead--blue--individual"
                        >
                            Fecha de final
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="encargado"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                            tdStyle={this.state.BreakLine}
                            className="thead--blue--individual"
                        >
                            Encargado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cliente"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? (
                                    <div>
                                        {cell.nombre}
                                    </div>
                                ) : (
                                    "---"
                                );
                            }}
                            tdStyle={this.state.BreakLine}
                            className="thead--blue--individual"
                        >
                            Cliente
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            dataFormat={(cell, row) => {
                                let props = {};
                                if (row.activo == true && row.finalizado == false) {
                                    if (role == 1) props.general = true;
                                    if (role == 1 || role == 10)
                                        props.recursos = true;
                                    if (role == 1) props.eliminar = eliminar;
                                }
                                const actions = standardActions({ ...props });
                                return actions(cell, row);
                            }}
                            className="thead--blue--individual"
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
