import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos/recursos';
import CrearEditar from './CrearEditar';


const ms2p = (state) => {
  return {
    ...state.recursos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);