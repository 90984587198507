import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from './CrearEditarForm';
import React, { Component } from 'react';
import { api } from "api";

class CrearEditar extends Component {

    state = {
        tipos_proyecto: [],
        puestos: [],
        tipos_contratacion: [],
        edit: false,
        loader: true,
        id: null,
    }

    componentDidMount(){
        api.get("puesto").then(response => {
            this.setState({ puestos: response.results })
            api.get("tipo_proyecto", { activo: true }).then(response2 => {
                this.setState({ tipos_proyecto: response2.results })
                api.get("tipo_contratacion", { activo: true }).then(response3 => {
                    this.setState({ tipos_contratacion: response3.results, loader: false })
                    const edit = this.props.match.params.id ? true : false
                    this.setState({ edit })
                    if(edit == true){
                        const id = this.props.match.params.id
                        this.setState({ id })
                        this.props.obtenerRecurso(id)
                    }
                })
            })
            
        })
    }

    onSubmit = (data) => {
        const { edit, id } = this.state
        const { onSubmit, actualizarRecurso } = this.props
        if(edit){
            actualizarRecurso(data, id)
        } else { 
            onSubmit(data)
        }
    }

    render() {
        const { loader } = this.props
        
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">{this.state.edit ? "EDITAR" : "CREAR"} RECURSO</h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader || this.state.loader} light>
                            <CrearEditarForm 
                                onSubmit={this.onSubmit} 
                                puestos={this.state.puestos} 
                                tipos_proyecto={this.state.tipos_proyecto}
                                tipos_contratacion={this.state.tipos_contratacion}
                                edit={this.state.edit}
                                renunciaDespido={this.props.renunciaDespido}
                                id={this.state.id}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CrearEditar