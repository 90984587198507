import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSelectField } from "../../Utils/renderField";
import _ from "lodash";
import TablaRecursos from "./TablaRecursos";
import CrearRecurso from "./CrearRecurso";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Finalizar from './Finalizar';

let CrearEditarForm = (props) => {
    const {
        handleSubmit,
        recursos,
        temp_tag,
        tags,
        setTags,
        tipos_proyecto,
        puestos,
        clientes,
        fases,
        recursos_submit,
        setRecurosSubmit,
        recursos_update,
        edit,
        role,
        finalizarProyecto,
        finalizado,
        edit_type,
        planificacion,
        setPlanificacion,
        planificacion_update,
        tipo_proyecto,
        finalizar_data
    } = props;
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };
    const [_fases, setFases] = useState([]);
    const [_tipo, setTipos] = useState([]);
    const [_recursos, setRecursos] = useState([]);
    const [fase_proyecto, setFase] = useState(null);
    const [tipo, setTipo] = useState(null);
    const [persona, setPersona] = useState(null);
    const [edit_index, setEditIndex] = useState(null);
    const [porcentaje, setPorcentaje] = useState("");
    const [update, setUpdate] = useState(true);
    const [edit_recurso, setEditRecurso] = useState(null);
    const [popup_finalizado, setPopupFinalizado] = useState(false);

    // const compareName = (a, b) => {
    //     if (a.recurso_nombre < b.recurso_nombre) {
    //         return -1;
    //     }
    //     if (a.recurso_nombre > b.recurso_nombre) {
    //         return 1;
    //     }
    //     return 0;
    // };

    const compareDate = (a, b) => {
        if (a.fecha_inicio < b.fecha_inicio) {
            return -1;
        }
        if (a.fecha_inicio > b.fecha_inicio) {
            return 1;
        }
        return 0;
    };

    const updateArray = (data, recurso_index = null) => {
        const recursos_submit_copy = [...recursos_submit];
        if (recurso_index) {
            recursos_submit_copy[recurso_index.fase_proyecto].recursos.splice(
                recurso_index.index,
                1
            );
        }
        if (data) {
            const index = recursos_submit.findIndex(
                (e) => e.id == data.fase_proyecto
            );
            recursos_submit_copy[index].recursos.push(data);
            //recursos_submit_copy[index].recursos.sort(compareName);
            recursos_submit_copy[index].recursos.sort(compareDate);
            //recursos_submit_copy[index].recursos.sort(compareName);
        }
        setRecurosSubmit(recursos_submit_copy);
        setUpdate(!update);
    };

    const updateArray1 = (i, value, id) => {
        const planificacion_copy = [...planificacion];
        const planificacion_item_copy = { ...planificacion_copy[i] };
        planificacion_item_copy[id] = value;
        planificacion_copy[i] = planificacion_item_copy;
        setPlanificacion(planificacion_copy);
    };

    //Variables dinámicas
    useEffect(() => {
        const new_array = [];
        fases.forEach((e) =>
            new_array.push({
                id: e.id,
                nombre: e.nombre,
                cantidad_recursos: "",
                horas_planificadas: "",
                horas_ejecutadas: "",
                fecha_inicio: "",
                fecha_fin: "",
            })
        );
        if (new_array.length > 0) {
            if (planificacion_update) {
                if (planificacion_update.length > 0 && edit) {
                    planificacion_update.forEach((f) => {
                        let index = new_array.findIndex(
                            (h) => h.id == f.fase_proyecto
                        );
                        new_array[index].cantidad_recursos =
                            f.cantidad_recursos;
                        new_array[index].horas_planificadas = f.horas_planificadas;
                        new_array[index].fecha_inicio = f.fecha_inicio;
                        new_array[index].fecha_fin = f.fecha_fin;
                    });
                }
            }
            setPlanificacion(new_array);
        }
    }, [fases, edit, planificacion_update]);

    useEffect(() => {
        const new_array = [];
        fases.forEach((e) =>
            new_array.push({
                id: e.id,
                nombre: e.nombre,
                recursos: [],
                fecha_inicio: "",
                fecha_fin: "",
            })
        );
        if (new_array.length > 0) {
            if (recursos_update) {
                if (recursos_update.length > 0 && edit) {
                    recursos_update.forEach((f) => {
                        let index = new_array.findIndex(
                            (h) => h.id == f.fase_proyecto
                        );
                        index = index == -1 ? 0 : index;
                        new_array[index].recursos.push(f);
                        //new_array[index].recursos.sort(compareName);
                        new_array[index].recursos.sort(compareDate);
                        //new_array[index].recursos.sort(compareName);
                    });
                }
                if (planificacion) {
                    planificacion.forEach((f) => {
                        let index = new_array.findIndex((h) => h.id == f.id);
                        new_array[index].fecha_inicio = f.fecha_inicio;
                        new_array[index].fecha_fin = f.fecha_fin;
                    });
                }
            }
            setRecurosSubmit(new_array);
        }
    }, [fases, recursos_update, edit, planificacion]);

    useEffect(() => {
        const _fases_copy = [..._fases];
        fases.forEach((option) => {
            _fases_copy.push({
                ...option,
                label: option["nombre"],
                value: option["id"],
            });
        });
        setFases(_fases_copy);
        if (fase_proyecto !== null && fase_proyecto !== undefined) {
            setFase(_.find(_fases, { fase_proyecto }));
        }
    }, [fases]);

    useEffect(() => {
        setTipos(puestos);
        if (tipo !== null && tipo !== undefined) {
            setTipo(_.find(_tipo, { tipo }));
        }
    }, [puestos]);

    useEffect(() => {
        const _recursos_copy = [..._recursos];
        recursos.forEach((option) => {
            _recursos_copy.push({
                ...option,
                label: option["nombre"],
                value: option["id"],
            });
        });
        setRecursos(_recursos_copy);
        if (persona !== null && persona !== undefined) {
            setPersona(_.find(_recursos, { persona }));
        }
    }, [recursos]);

    return (
        <React.Fragment>
            <form name="CrearEditarForm" onSubmit={handleSubmit}>
                <div className="card card-login w-100">
                    {edit && (role == 1 || role == 10) ? (
                        <div className="row d-flex justify-content-end">
                            <button
                                type="button"
                                disabled={finalizado}
                                className="btn btn-info"
                                onClick={() => {
                                    if(tipo_proyecto == 1)
                                        setPopupFinalizado(true)
                                    else
                                        finalizarProyecto()
                                }}
                            >
                                Finalizar proyecto
                            </button>
                            <Popup open={popup_finalizado} onClose={() =>setPopupFinalizado(false)}>
                                <Finalizar finalizarProyecto={finalizarProyecto} finalizar_data={finalizar_data} horas={planificacion} updateArray1={updateArray1}/>
                            </Popup>
                        </div>
                    ) : null}
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="nombre">Nombre del proyecto</label>
                            <Field
                                name="nombre"
                                label="Nombre"
                                component={renderField}
                                type="text"
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="puesto">Tipo de proyecto</label>
                            <Field
                                name="tipo_proyecto"
                                label="Tipo de proyecto"
                                options={tipos_proyecto}
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="puesto">Encargado</label>
                            <Field
                                name="encargado"
                                label="Encargado"
                                options={recursos.filter((recurso) =>
                                    recurso.puesto.nombre
                                        .toUpperCase()
                                        .includes("SCRUM")
                                )}
                                isClearable={true}
                                component={renderSelectField}
                                labelKey="nombre"
                                valueKey="id"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="cliente">Cliente</label>
                            <Field
                                name="cliente"
                                label="Cliente"
                                options={clientes}
                                isClearable={true}
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Fecha de inicio programado
                            </label>
                            <Field
                                name="fecha_inicio"
                                label="Fecha de inicio"
                                component={renderField}
                                type="date"
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Fecha final programada
                            </label>
                            <Field
                                name="fecha_fin"
                                label="Fecha final programada"
                                component={renderField}
                                type="date"
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Porcentaje de tiempo del encargado
                            </label>
                            <Field
                                name="encargado_porcentaje_asignacion"
                                label="Porcentaje de tiempo del encargado"
                                component={renderField}
                                className="input--style"
                                type="number"
                                min="0"
                                max="100"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Fecha de inicio ejecución
                            </label>
                            <Field
                                name="fecha_inicio_ejecucion"
                                label="Fecha de inicio"
                                component={renderField}
                                className="input--style"
                                type="date"
                                disabled={true || role != 1}
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Fecha final ejecución
                            </label>
                            <Field
                                name="fecha_fin_ejecucion"
                                label="Fecha final programada"
                                component={renderField}
                                className="input--style"
                                type="date"
                                disabled={true || role != 1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">Etiquetas</label>
                            <Field
                                name="tags_input"
                                label="Etiquetas"
                                component={renderField}
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    role != 1
                                }
                                onKeyPress={(key) => {
                                    if (key.key.toUpperCase() == "ENTER") {
                                        key.preventDefault();
                                        let isvalid = true;
                                        isvalid &=
                                            temp_tag.replaceAll(" ", "") !== "";
                                        tags.forEach((tag) => {
                                            isvalid &=
                                                tag.replaceAll(" ", "") !==
                                                temp_tag.replaceAll(" ", "");
                                        });
                                        if (isvalid) {
                                            setTags([...tags, temp_tag]);
                                        }
                                        props.change("tags_input", "");
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-8 row">
                            {tags.map((tag) => {
                                return (
                                    <div
                                        className="col text-center mt-3"
                                        key={tag}
                                    >
                                        <span className="badge badge-pill badge-info tag">
                                            {tag}
                                            <i
                                                className="fa fa-window-close ml-2"
                                                style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    if (role == 1) {
                                                        setTags(
                                                            tags.filter(
                                                                (t) => t != tag
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12 row">
                            <h5 style={{ marginLeft: "15px" }}>Fases</h5>
                            <div className="table-responsive d-flex justify-content-center">
                                <table
                                    className="table resources--table"
                                    style={{ width: "98%" }}
                                >
                                    <thead className="thead--blue">
                                        <tr>
                                            <th scope="col">Fase</th>
                                            <th scope="col">
                                                Cantidad recursos
                                            </th>
                                            <th scope="col">Horas Programadas</th>
                                            <th scope="col">Fecha inicio</th>
                                            <th scope="col">Fecha final</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {planificacion.map((e, i) => (
                                            <tr
                                                key={i}
                                                className="border--lr border--bt border--last"
                                            >
                                                <td>{e.nombre}</td>
                                                <td>
                                                    <input
                                                        name={`${i}_cantidad_recursos`}
                                                        id={`${i}_cantidad_recursos`}
                                                        className="form-control input--style"
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "cantidad_recursos"
                                                            )
                                                        }
                                                        value={
                                                            e.cantidad_recursos
                                                        }
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            role != 1
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_horas_planificadas`}
                                                        id={`${i}_horas_planificadas`}
                                                        className="form-control input--style"
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "horas_planificadas"
                                                            )
                                                        }
                                                        value={e.horas_planificadas}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            role != 1
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_fecha_inicio`}
                                                        id={`${i}_fecha_inicio`}
                                                        className="form-control input--style"
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "fecha_inicio"
                                                            )
                                                        }
                                                        value={e.fecha_inicio || ''}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            role != 1
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_fecha_fin`}
                                                        id={`${i}_fecha_fin`}
                                                        className="form-control input--style"
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "fecha_fin"
                                                            )
                                                        }
                                                        value={e.fecha_fin || ''}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            role != 1
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {edit && edit_type == "recursos" ? (
                    <React.Fragment>
                        <div className="d-flex flex-column align-items-start pt-3">
                            <h3 className="title--blue">
                                FASES  Y RECURSOS DEL PROYECTO
                            </h3>
                        </div>
                        <hr />
                        <div className="card card-login w-100">
                            <CrearRecurso
                                _tipos={_tipo}
                                _fases={_fases}
                                _recursos={_recursos}
                                tipo={tipo}
                                fase_proyecto={fase_proyecto}
                                persona={persona}
                                updateArray={updateArray}
                                setFase={setFase}
                                setTipo={setTipo}
                                setPersona={setPersona}
                                edit_index={edit_index}
                                setEditIndex={setEditIndex}
                                porcentaje={porcentaje}
                                setPorcentaje={setPorcentaje}
                                update={update}
                                edit_recurso={edit_recurso}
                                setEditRecurso={setEditRecurso}
                                select_style={select_style}
                            />
                            <TablaRecursos
                                recursos_submit={recursos_submit}
                                setEditIndex={setEditIndex}
                                setFase={setFase}
                                setPersona={setPersona}
                                setPorcentaje={setPorcentaje}
                                setTipo={setTipo}
                                updateArray={updateArray}
                                _fases={_fases}
                                _recursos={_recursos}
                                _tipo={_tipo}
                                setEditRecurso={setEditRecurso}
                            />
                            <div className="form-group has-feedback invisible">
                                <label htmlFor="tipo_contratacion">
                                    Porcentaje de tiempo del encargado
                                </label>
                                <Field
                                    name="encargado_porcentaje_asignacion"
                                    label="Porcentaje de tiempo del encargado"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
                <div className="buttons-box mt-3 mb-3">
                    <Link
                        to="/proyectos"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </Link>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
};

// VALIDACIONES
CrearEditarForm = reduxForm({
    form: "CrearEditarForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            tipo_proyecto: validators.exists()("Este campo es requerido"),
            encargado_porcentaje_asignacion: validators.exists()(
                "Este campo es requerido"
            ),
        });
    },
})(CrearEditarForm);

// SELECTOR DE FORM PROPS
const selector = formValueSelector("CrearEditarForm");
CrearEditarForm = connect((state) => {
    const temp_tag = selector(state, "tags_input");
    const selected_recursos = selector(state, "recursos");
    const fecha_inicio_form = selector(state, "fecha_inicio");
    const fecha_fin_form = selector(state, "fecha_fin");
    const fecha_fin_retraso_form = selector(state, "fecha_fin_retraso");
    let fecha_inicio_calculated = null,
        fecha_fin_calculated = null,
        fecha_fin_retraso_calculated = null;
    return {
        temp_tag,
        selected_recursos,
        fecha_inicio_form,
        fecha_fin_form,
        fecha_inicio_calculated,
        fecha_fin_calculated,
        fecha_fin_retraso_form,
        fecha_fin_retraso_calculated,
    };
})(CrearEditarForm);

export default CrearEditarForm;
